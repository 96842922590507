import { useState } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { FormInput } from "../../forms/Form";

/**
 * 
 * @param columnToggleAttribute - attribute name added to toggle on eye icon click, must be boolean
 * @returns 
 */
export default function DraggableColumnsListUi( { modalTitle , columnsConfig, formSubmitCallback, columnToggleAttribute ,recordsPerPage, inputFieldAttributes = {}, alertAttributes = {}, buttonLoader = false }) {
    
    //form state for UI
    const resetStates = {
        "noOfRecords" : recordsPerPage,
    }

    const [ formState, setFormState ] = useState(resetStates)
    const [columnList, setColumnList ] = useState( columnsConfig )

    const handleDragStart = (event, item) => {
        event.dataTransfer.setData("columnName", item.column )
    }

    const handleDragOver = (event) => event.preventDefault()

    const handleDrop = (event, { ...targetColumn} ) => {
        
        //make a deep copy
        const draggedColumn = {...columnList.find( (item) => item.column === event.dataTransfer.getData("columnName") ) }

        setColumnList(columnList.map( (item, index) => {
            
            if( item.column === draggedColumn.column ) {
                item.sortOrder = targetColumn.sortOrder
            } else if( item.column === targetColumn.column ) {
                item.sortOrder = draggedColumn.sortOrder
            }
            return item
        }) )
    }

    const handleToggleColumn = ( targetColumn ) => {
        setColumnList(columnList.map( (item, index) => {
            
            if( item.column === targetColumn.column ) {
                item[ columnToggleAttribute ] = !item[ columnToggleAttribute ]
            }

            return item
        }) )

        //TODO: make sure atleast one column has columnToggleAttribute to true
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        formSubmitCallback( columnList, formState['noOfRecords'] )
    }


    return <div className='card card-primary' >
                <div className="card-header">
                    <h3 className="card-title">{ modalTitle }</h3>
                </div>
                <div className="card-body">
                    <ul className='list-group p-3 rounded'>
                        {
                            //sort and display
                        columnList.filter( item => !item.hidden ).sort( (first, second) => first.sortOrder - second.sortOrder ).map( (item, index) => { 
                            return  <li key={"disItem-"+item.column+item.sortOrder }  
                                        draggable 
                                        onDragStart= { (event) => handleDragStart(event, item) }
                                        onDragOver= { (event)=> handleDragOver(event) }
                                        onDrop= { (event)=>{ handleDrop(event, item) }}
                                        className='list-group-item  d-flex justify-content-between align-items-center'>
                        { item.title }
                        
                        { item[ columnToggleAttribute ] ? <FaEye onClick={ (event) => handleToggleColumn(item) } /> : <FaEyeSlash onClick={ (event) => handleToggleColumn(item) } /> }
                        
                        </li> } ) }
                    </ul>

                    <div className="col-12">
                        <FormInput {...inputFieldAttributes} htmlID="noOfRecords" labelText={ (inputFieldAttributes['labelText'] ? inputFieldAttributes['labelText'] : "Records Per Page") } formState={ formState } defaultValue={formState['noOfRecords']} setValue={ setFormState } controlType="text" />

                        { Object.keys(alertAttributes).length > 0 && <div className= { "alert alert-default-"+alertAttributes["className"] } role="alert" style={{whiteSpace: "pre-line"}}>
                        { alertAttributes["message"] }

                        { alertAttributes['downloadLink'] !== "" && <><br/> <a style={{ color: "#007bff"}} href= { alertAttributes['downloadLink'] }  title="Download Report" target="_blank" rel="noreferrer">Download Report</a></> }
                        </div> }
                    </div>

                </div>

                <div className="card-footer">
                    <button className={"btn btn-primary "+ ( buttonLoader ? "disabled" : "")} onClick={handleFormSubmit}>
                    { buttonLoader && <div className="spinner-border spinner-border-sm mr-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> } Submit
                    </button>
                </div>
            </div> 
}