import { useState } from "react"

import DraggableColumnsListUi from "./DraggableColumnsListUi"
import { callExportModelApi } from "../../../services/ApiService"

export default function ExportColumnsList({ displayProperties, entity, sortOrder, sortColumn, appliedFilters, pageGroupBy }) {

    const [ loader, setLoader ] = useState(false)
    const defaultAlertAttributes = {
        "className" : "info",
        "message" : "Download link will appear here",
        "downloadLink" : ""
    }
    const [alertAttributes, setAlertAttributes] = useState(defaultAlertAttributes)
    
    const setErrors = ( errors ) => {
        setLoader( false )
        if( Object.keys(errors).length )
            setAlertAttributes( {...alertAttributes, "className" : "danger", "message" : "There is an error in processing request" } )
    }

    const setSuccessResponse = ( response ) => {
        setLoader( false )
        setAlertAttributes( {...alertAttributes, "className" : "success", "message" : "Click to download the file", "downloadLink" : response.data.downloadLink } )
    }

    const handleFormSubmit = async (propertiesConfig, noOfRecords ) => {
        //set default attributes
        setAlertAttributes( defaultAlertAttributes )
        setLoader( true )
        //get list of display properties
        //const importedColumns = propertiesConfig.filter( item => item.display ).map( item => item.column )
        const importedColumns = propertiesConfig.filter( item => item.isPrimary || (item.display && item.aggregate === "") ).map( item => item.column )
        const appliedGroupBy = pageGroupBy.length ? importedColumns.filter( item => !item.includes(".") ) : []

        const serviceArgs = {
            displayProperties: importedColumns, //array of display = true column names
            sortOrder: sortOrder ,
            sortColumn: sortColumn ,
            filters: appliedFilters,
            recordsPerPage: noOfRecords,
            recordLevelAggregates : propertiesConfig.filter( item => item.display && item.aggregate !== "" ).map( item => {
                return { 
                  column: item.column,
                  aggregate: item.aggregate
                }
            }),
            groupBy: appliedGroupBy
        }

        //get no of records for export
        await callExportModelApi( entity, serviceArgs, setErrors, setSuccessResponse )
    }

    return <DraggableColumnsListUi modalTitle={"Export Records"} columnsConfig={ displayProperties } formSubmitCallback={handleFormSubmit} columnToggleAttribute={"display"} recordsPerPage={0} inputFieldAttributes={{
        "placeholderText" : "All Records",
        "labelText" : "No Of Rows To Export"
    }} alertAttributes={ alertAttributes } buttonLoader={ loader } />
}